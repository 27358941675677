import { UserRole } from 'src/app/shared/models/enums/roles';
import { ViewMenuItem } from '../models';
import { findIntersectionOfTwoLists } from '.';
export const getValidStartPage = ({
  userRoles,
  menuItems,
}: {
  userRoles: Array<UserRole>;
  menuItems: Array<ViewMenuItem>;
}) => {
  const startPages = menuItems.filter((menu) => menu.startPage === true);

  let validPage = null;
  for (const sp of startPages) {
    const matchingStartPage = !!findIntersectionOfTwoLists<UserRole>(
      userRoles,
      sp.roles
    ).length;
    if (matchingStartPage) {
      validPage = sp;
      break;
    }
  }
  return validPage;
};
