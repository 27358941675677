import { Observable, filter, map } from 'rxjs';
import { IStateDataDigitalAuto } from '../../custom/digital-auto';

export const observeFetchSuccess = <T>(
  observable: Observable<IStateDataDigitalAuto<T>>
) => {
  return observable.pipe(
    filter((d) => d.progress === 'dataFetchSuccessfull'),
    map((response) => response.data)
  );
};

export const subscribeAndReturnObservable = <T>(observable: Observable<T>) => {
  observable.subscribe();
  return observable;
};
