export class ImageSliderUtility {
  private currentIndex: number = 0;
  private totalImages: number = 0;

  constructor(totalImages: number, initialIndex: number = 0) {
    this.totalImages = totalImages;
    this.currentIndex = initialIndex;
  }

  move(direction: 'left' | 'right'): void {
    if (direction === 'left') {
      this.currentIndex =
        (this.currentIndex - 1 + this.totalImages) % this.totalImages;
    } else if (direction === 'right') {
      this.currentIndex = (this.currentIndex + 1) % this.totalImages;
    }
  }

  getCurrentIndex(): number {
    return this.currentIndex;
  }
}
